<template>
  <b-list-group-item
    class="d-flex align-items-center border-0"
    v-b-modal.modal-add-customer
  >
    <b-button
      v-if="showBtn"
      class="flex-shrink-0 mb-2 mb-xl-0"
      variant="primary"
    ><i class="fa fa-plus"></i>{{ addButtonText }}
    </b-button>

    <b-modal
      v-model="show"
      id="modal-add-customer"
      :title="title"
      body-class="modal-height"
      centered
      @show="refreshSearch"
    >
      <b-input-group>
        <b-form-input placeholder="請輸入姓名或Line Uid" v-model="customerKeyword" @keyup.enter="fetchCustomers"></b-form-input>
        <b-input-group-append>
          <b-button @click="fetchCustomers"><i class="fa fa-search"></i></b-button>
        </b-input-group-append>
      </b-input-group>
      <div class="text-center mt-3 mb-3 loading-spinner" v-if="loading">
        <b-spinner variant="secondary" small />
      </div>
      <div class="scrolling" v-if="customers.length > 0 && !loading">
        <div v-for="customer in customers" v-bind:key="customer.id">
          <div class="d-flex align-items-center py-2 staff-info" @click="handleSelected(customer)">
            <b-avatar
              class="mx-2"
              :src="customer.avatar_url"
            ></b-avatar>
            <div
              class="
                mr-auto
                font-weight-bold
                text-nowrap text-truncate
              "
            >
              {{ customer.name }}
              <span class="staff-list-info">
                {{ customer.email }}<br>
                {{ displayCustomerInfo(customer) }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="selectedCustomer"
        class="d-flex align-items-center py-3"
      >
        <b-avatar
          class="mr-2"
          :src="selectedCustomer.avatar_url"
        ></b-avatar>
        <div
          class="
            mr-auto
            font-weight-bold
            text-nowrap text-truncate
            flex-fill
          "
        >
          {{ selectedCustomer.name }}
          <span class="staff-list-info">
            {{ selectedCustomer.email }}<br>
            {{ displayCustomerInfo(selectedCustomer) }}
          </span>
        </div>
        <b-button
          size="xs"
          pill
          variant="danger"
          class="p-1 mr-2"
          @click="cancelSelected"
          ><i class="fa fa-times m-0"></i
        ></b-button>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <div class="float-right">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 arrow-point"
              @click="changePage(-1)"
            >
              <path
                d="M17.1816 20.3398C17.1816 20.5352 17.2598 20.7109 17.4062 20.8574L24.7598 27.9375C24.8965 28.0742 25.0723 28.1523 25.2773 28.1523C25.6777 28.1523 25.9902 27.8398 25.9902 27.4297C25.9902 27.2246 25.9121 27.0586 25.7852 26.9219L18.9395 20.3398L25.7852 13.748C25.9121 13.6113 25.9902 13.4355 25.9902 13.2402C25.9902 12.8301 25.6777 12.5273 25.2773 12.5273C25.0723 12.5273 24.8965 12.5957 24.7598 12.7324L17.4062 19.8125C17.2598 19.959 17.1816 20.1348 17.1816 20.3398Z"
                fill="black"
              />
            </svg>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-5 arrow-point"
              @click="changePage(1)"
            >
              <path
                d="M25.3438 20.3398C25.3438 20.1348 25.2656 19.959 25.1191 19.8125L17.7656 12.7324C17.6289 12.5957 17.4531 12.5273 17.248 12.5273C16.8477 12.5273 16.5352 12.8301 16.5352 13.2402C16.5352 13.4355 16.6133 13.6113 16.7402 13.748L23.5859 20.3398L16.7402 26.9219C16.6133 27.0586 16.5352 27.2246 16.5352 27.4297C16.5352 27.8398 16.8477 28.1523 17.248 28.1523C17.4531 28.1523 17.6289 28.0742 17.7656 27.9375L25.1191 20.8574C25.2656 20.7109 25.3438 20.5352 25.3438 20.3398Z"
                fill="black"
              />
            </svg>
            <b-button
              variant="danger"
              size="sm"
              class="mr-2 ml-5"
              @click="closeModal"
            >
              取消
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              @click="submitAddMember"
            >
              確定
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </b-list-group-item>
</template>

<script>
import customerApi from "@/apis/customer";
import meCustomerApi from "@/apis/me/customer";
import merchantApi from "@/apis/merchant";
import * as consts from '@/consts'

export default {
  props: {
    sourceType: {
      type: String,
      default: 'line',
    },
    staff: {
      type: Object,
      default: null,
    },
    addButtonText: {
      type: String,
      default: "綁定客戶",
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '選擇客戶'
    },
    onlyBelongedCustomer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCustomer: null,
      customerKeyword: null,
      customers: [],
      allCustomers: [],
      consts,
      page: 1,
      lastPage: 1,
      show: false,
      loading: false,
    }
  },
  computed: {
  },
  methods: {
    showModal() {
      this.$bvModal.show('modal-add-customer')
    },
    async fetchCustomers() {
      this.loading = true
      let params = {}
      params.per_page = 10
      params.page = this.page
      if (this.customerKeyword) params.keyword = this.customerKeyword

      let resp
      if (this.onlyBelongedCustomer) {
        resp = await meCustomerApi.getCustomers({
          doesBound: true,
          allBoundType: true,
          merchant_type: 'LINE',
          is_paginate: true,
        })
      } else {
        if (this.sourceType != 'line') {
          const { data } = await merchantApi.list({});
          const merchantId = data.data.find((merchant) => merchant.type == (this.sourceType).toUpperCase())?.id;
          params.merchant_hashid = merchantId
          resp = await customerApi.listV2(params)
        } else {
          resp = await customerApi.getLineMerchantCustomers(params)
        }
      }
      const { data } = resp

      this.customers = data.data
      // 這個做預留 就不用一直重複打
      this.allCustomers = data.data
      this.lastPage = data.meta.last_page
      this.loading = false
    },
    displayCustomerInfo(customer) {
      return ((customer.outer_code) ? customer.outer_code + " " : "") + (customer.mobile_phone ?? "");
    },
    async submitAddMember() {
      this.$emit("bind", this.selectedCustomer)
      this.closeModal()
    },
    refreshSearch() {
      this.selectedCustomer = null;
      this.customerKeyword = null;
      this.fetchCustomers()
    },
    handleSelected(e) {
      this.selectedCustomer = e;
      this.customers = []
    },
    cancelSelected() {
      this.customers = this.allCustomers
      this.selectedCustomer = null
    },
    changePage(page) {
      if (this.lastPage < this.page + 1) {
        this.page -= 1
        return
      }
      if (this.page + page == 0) {
        return
      }
      this.page += page
      this.customers = []
      this.fetchCustomers()
    },
    closeModal() {
      this.show = false
    }
  }
}
</script>

<style lang="scss">
.modal-height {
  height: 50vh;
}

.staff-list-info {
  font-size: 0.75rem;
  color: gray;
  font-weight: 400;
}

.scrolling {
  overflow-y: scroll;
  height: 40vh;
}

.staff-info:hover {
  cursor: pointer;
  background-color: gray;
  color: white;
  width: 100%;

  .staff-list-info {
    color: white;
  }
}
</style>
