import https from "./https";
import store from "@/store";

const customer = {
  listV2(params) {
    const organization = store.state.general.organization;
    return https.get(
      `/admin/organizations/${organization.id}/customers`,
      {
        params,
      }
    );
  },
  listWithProfile(merchantID, params) {
    const organization = store.state.general.organization;
    return https.get(
      `/admin/organizations/${organization.id}/merchants/${merchantID}/customers-with-profile`,
      {
        params,
      }
    );
  },
  getSameUserCustomers(customerID) {
    const organization = store.state.general.organization;
    return https.get(
      `/admin/organizations/${organization.id}/customers/${customerID}/same-user-customers`
    );
  },
  getCustomer(customerID) {
    const organization = store.state.general.organization;
    return https.get(
      `/admin/organizations/${organization.id}/customers/${customerID}`
    );
  },

  storeCustomer(customerId, params) {
    const organization = store.state.general.organization;
    return https.put(
      `/admin/organizations/${organization.id}/customers/${customerId}`, params
    );
  },

  getCustomerProfiles(customerId) {
    const organization = store.state.general.organization;
    return https.get(
      `/admin/organizations/${organization.id}/customers/${customerId}/profiles`
    );
  },

  storeCustomerProfiles(customerId, params) {
    const organization = store.state.general.organization;
    return https.put(
      `/admin/organizations/${organization.id}/customers/${customerId}/profiles`, params
    );
  },

  getCustomerTags(customerId) {
    const organization = store.state.general.organization;
    return https.get(
      `/admin/organizations/${organization.id}/customers/${customerId}/tags`
    );
  },

  setCustomerTags(customerId, params) {
    const organization = store.state.general.organization;
    return https.put(
      `/admin/organizations/${organization.id}/customers/${customerId}/tags`, params
    );
  },

  unbind(customerID) {
    const organization = store.state.general.organization;
    return https.put(
      `/admin/organizations/${organization.id}/customers/${customerID}/unbind`
    );
  },
  unregister(customerID) {
    const organization = store.state.general.organization;
    return https.put(
      `/admin/organizations/${organization.id}/customers/${customerID}/unregister`
    );
  },
  reset(customerID) {
    const organization = store.state.general.organization;
    return https.put(
      `/admin/organizations/${organization.id}/customers/${customerID}/reset`
    );
  },
  importCustomers(merchantID, data) {
    const organization = store.state.general.organization;

    return https.post(
      `/admin/organizations/${organization.id}/merchants/${merchantID}/customers/import`,
      data
    );
  },
  getStaffs(customerID, params) {
    const organization = store.state.general.organization;
    return https.get(
      `/admin/organizations/${organization.id}/customers/${customerID}/staffs`,
      { params }
    );
  },
  changeStaff(customerID, params) {
    const organization = store.state.general.organization;
    return https.post(
      `/admin/organizations/${organization.id}/customers/${customerID}/change-staff`,
      params
    );
  },
  unbindStaff(customerID, params) {
    const organization = store.state.general.organization;
    return https.post(
      `/admin/organizations/${organization.id}/customers/${customerID}/unbind-staff`,
      params
    );
  },
  changeBoundType(customerID, params) {
    const organization = store.state.general.organization;
    return https.post(
      `/admin/organizations/${organization.id}/customers/${customerID}/change-bound-type`,
      params
    );
  },
  bindStaff(customerID, params) {
    const organization = store.state.general.organization;
    return https.post(
      `/admin/organizations/${organization.id}/customers/${customerID}/bind-staff`,
      params
    );
  },
  getLineMerchantCustomers(params) {
    const organization = store.state.general.organization;
    return https.get(
      `/admin/organizations/${organization.id}/line-merchants/customers`,
      { params }
    );
  },
  merchantList() {
    const organization = store.state.general.organization;
    return https.get(`/admin/organizations/${organization.id}/customers/merchants`);
  },
  syncLineData(customerID) {
    const organization = store.state.general.organization;
    return https.post(`/admin/organizations/${organization.id}/customers/${customerID}/sync-line-data`);
  }
};

export default customer;
