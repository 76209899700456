<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            預約管理
          </b-breadcrumb-item>

          <b-breadcrumb-item v-if="checkPermission([consts.BOOKING_PRESET_LIST])" :to="{ name: 'BookingPresetList' }">
            預約設定檔列表
          </b-breadcrumb-item>

          <b-breadcrumb-item v-if="bookingPreset.name" :to="{ name: 'BookingList', params: { id: bookingPreset.id }}">
            {{ bookingPreset.name }}
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{
              isEditing ? '編輯預約' : '新增預約'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-12">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- begin: dynamic form -->
              <div>
                <h4 class="mb-2 mb-xl-0 font-weight-bold">{{ isEditing ? "編輯預約" : "新增預約" }} - {{ bookingPreset.name }}</h4>
                <b-form-group v-bind:label="'預約會員'" label-cols-sm="3" label-cols-lg="2" content-cols-lg="9" style="width: 100%">
                  <div v-if="customer" class="d-flex align-items-center py-2 staff-info">
                    <b-avatar
                      class="mx-2"
                      :src="customer.avatar_url"
                    ></b-avatar>
                    <div
                      class="
                        mr-auto
                        font-weight-bold
                        text-nowrap text-truncate
                      "
                    >
                      {{ customer.name }}
                      <span class="staff-list-info">
                        {{ customer.email }}<br>
                        {{ displayCustomerInfo(customer) }}
                      </span>
                    </div>
                  </div>
                  <StaffAddCustomer
                    ref="staffAddCustomer"
                    :add-button-text="addCustomerButtonText"
                    @bind="handleSelectCustomer"
                    :sourceType="'line'"
                  ></StaffAddCustomer>
                </b-form-group>
                <DynamicFormDashboard
                  ref="dynamicForm"
                  :input-subjects="bookingPreset.config.fields"
                  v-model="inputForm"
                ></DynamicFormDashboard>
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center mt-4">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </div>
              <!-- end: dynamic form -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->

  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
import bookingApi from "@/apis/booking";
// import DynamicForm from "@/components/DynamicForm/DynamicForm"
import DynamicFormDashboard from "@/components/DynamicForm/DynamicFormDashboard"
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import StaffAddCustomer from "@/pages/Dashboard/Staff/StaffAddCustomer.vue";

export default {
  components: {
    // DynamicForm,
    DynamicFormDashboard,
    StaffAddCustomer,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {};
  },
  data() {
    return {
      consts,
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      step: '',
      bookingPreset: {
        name: null,
        config: {
          fields: [],
        }
      },
      inputForm: {},
      booking: {
        customer_id: null,
        data: []
      },
      customer: null,
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    addCustomerButtonText() {
      return this.customer ? "重新選擇會員" : "選擇此預約所屬會員";
    },
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    async init () {
      this.bookingPreset.id = this.$route.params.id;
      await this.fetchBookingPreset();
      if (this.$route.name === "BookingEdit") {
        this.isEditing = true;
        this.booking.id = this.$route.params.booking_id;
        await this.fetchBooking();
      } else {
        this.isEditing = false;
      }
    },
    cancel() {
      this.$router.push({ name: 'BookingList', params: {id: this.bookingPreset.id} });
    },
    async submit() {
      const validate = await this.$refs.dynamicForm.validate();
      if (!validate) return;

      try {
        this.showLoading = true;

        // 將 item.data 組起來
        let data = [];

        for (let i = 0; i < this.bookingPreset.config.fields.length; i ++) {
          const field = this.bookingPreset.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦

          data.push({
            field_id: fieldId,
            title: field.title,
            value: this.inputForm[fieldId] ?? null,
          });
        }

        // 編輯表單
        if (this.isEditing) {
          await bookingApi.updateBooking(this.bookingPreset.id, this.booking.id, {
            data,
            customer_id: this.customer ? this.customer.id : null,
          })
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          await bookingApi.createBooking(this.bookingPreset.id, {
            data,
            customer_id: this.customer ? this.customer.id : null,
          });
          this.$swal("新增成功", "", "success");
          this.cancel();
        }
        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBookingPreset() {
      if (! this.bookingPreset.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await bookingApi.getBookingPreset(this.bookingPreset.id);

        if (! response.data.data.config) {
          response.data.data.config = {
            fields: [],
          };
        }

        this.bookingPreset = response.data.data;

        if (!this.bookingPreset.config?.fields || this.bookingPreset.config.fields.length === 0) {
          this.$swal("提醒", "此預約尚未設定欄位，須設定欄位後才能新增及編輯預約", "warning");
          this.$router.push({
            name: "BookingPresetList",
          });
          return;
        }

        this.bookingPreset.config.fields = this.bookingPreset.config.fields.map((field) => {
          return {
            id: field._id ?? field.config._id, // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
            ...field,
          }
        });

        let editInputForm = {};

        for (let i = 0; i < this.bookingPreset.config.fields.length; i ++) {
          const field = this.bookingPreset.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
          editInputForm[fieldId] = null;
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBooking() {
      try {
        this.showLoading = true;
        const response = await bookingApi.getBooking(this.bookingPreset.id, this.booking.id);
        this.booking = response.data;

        console.log(this.booking);

        let editInputForm = {};

        for (let i = 0; i < this.bookingPreset.config.fields.length; i ++) {
          const field = this.bookingPreset.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
          editInputForm[fieldId] = this.booking.data.find((data) => data.field_id === fieldId)?.value ?? null;
        }

        this.inputForm = editInputForm;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async handleSelectCustomer(customer) {
      this.$swal({
        title: "確定要加入會員嗎",
        html: `
          <div class="d-block">
            <div class="my-3 text-left">
              <div>會員姓名:${customer.name}</div>
            </div>
          </div>`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "返回",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確認加入",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.customer = customer;
          } catch (e) {
            console.log(e)
          } finally {
            this.$refs.staffAddCustomer.refreshSearch()
            this.$refs.staffAddCustomer.closeModal()
          }
        }
      })
    },
    displayCustomerInfo(customer) {
      return ((customer.outer_code) ? customer.outer_code + " " : "") + (customer.mobile_phone ?? "");
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon-for-done {
    display: none;
  }

  &.done {
    i {
      color: green;
    }

    .icon-for-done {
      display: unset;
    }

    .icon-for-undone {
      display: none;
    }
  }
}
</style>
